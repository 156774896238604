
.page {
  font-size: 6pt;
  max-width: 490pt;
  height: 900pt;
  /* max-height: 441.89px; */
  /* width: 21cm; */
  /* min-height: 29.7cm; */
  padding: 0pt 80pt 0pt 0pt;
  margin: 0;
  /* margin: 1cm auto; */
  background: white !important;
  /* box-shadow: inset 0 0 5px #ccc; */
}
.ant-spin-dot-item {
  background-color: red;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font: 12pt "Tahoma";
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}


.subpage {
  position: relative;
  border: 0px black solid;
  height: 213mm;
  width: 140mm;
  page-break-inside: avoid;
  page-break-after: always;
  /* position: relative;
  border: 0px black solid;
  height: 297mm;
  width: 210mm;
  page-break-inside: avoid;
  page-break-after: always; */
}

@page {
  size: A4;
  margin: 0;
}




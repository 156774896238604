.ant-spin-dot-item {
  background-color: red;
}

body {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  font: 12pt "Tahoma";
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.page-transfer {
  width: 21cm;
  min-height: 29.7cm;
  padding: 2cm;
  margin: 1cm auto;
  background: #ffffff;
  box-shadow: inset 0 0 5px #ccc;
}

.subpage-transfer {
  position: relative;
  border: 0px black solid;
  height: 270mm;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  .page-transfer {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
  .subpage-transfer {
    position: relative;
    border: 0px black solid;
    height: 256mm;
  }
  .subpage-transfer .footer {
    margin-top: 10;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
